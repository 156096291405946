'use client';

import { useEffect } from 'react';

import { AdSize } from '@/types/AdSizes';

interface AdSlotProps {
  path: string;
  size: AdSize[];
  id: string;
  targeting: { [key: string]: string | string[] };
  sizeMapping?: googletag.SizeMappingArray;
}

/**
 * A custom hook for managing Google Publisher Tag (GPT) ad slots.
 *
 * This hook handles the creation, display, and cleanup of ad slots using the GPT library.
 * It supports responsive ads through size mapping and custom targeting.
 *
 * @param {AdSlotProps} props - The properties required to define and display the ad slot.
 *
 * @example
 * useAdSlot({
 *   path: '/6001/example/path',
 *   size: [[300, 250], [728, 90]],
 *   id: 'div-gpt-ad-123456789-0',
 *   targeting: { key1: 'value1', key2: ['value2', 'value3'] },
 *   sizeMapping: [
 *     [[1024, 768], [[970, 250], [728, 90]]],
 *     [[768, 0], [[300, 250]]]
 *   ]
 * });
 * Notion Doc for the reference:
 * https://www.notion.so/motortrend/Ad-Setup-Example-1dc0ace3312d4edc938628bf781d3a45
 */
const useAdSlot = ({ path, size, id, targeting, sizeMapping }: AdSlotProps) => {
  // Most ads use `Ad.tsx` which sets the `refresh` kv. For those that don't, we
  // need to redefine the targeting values.
  targeting = {
    ...targeting,
    refresh: 'false',
  };

  useEffect(() => {
    const googletag = (window as Window).googletag || {};
    googletag.cmd = googletag.cmd || [];

    googletag.cmd.push(() => {
      const slot = googletag
        .defineSlot(path, size, id)
        ?.addService(googletag.pubads());

      if (slot) {
        if (
          sizeMapping &&
          Array.isArray(sizeMapping) &&
          sizeMapping.length > 0
        ) {
          const mapping = googletag.sizeMapping();
          sizeMapping.forEach(([viewportSize, slotSize]) => {
            mapping.addSize(viewportSize, slotSize);
          });
          slot.defineSizeMapping(mapping.build());
        }

        if (targeting) {
          Object.entries(targeting).forEach(([key, value]) => {
            slot.setTargeting(key, value);
          });
        }

        googletag.enableServices();
      }
    });

    googletag.cmd.push(() => {
      googletag.display(id);
    });

    // Cleanup function
    return () => {
      googletag.cmd.push(() => {
        googletag.destroySlots([
          googletag
            .pubads()
            .getSlots()
            .find((slot: googletag.Slot) => slot.getSlotElementId() === id),
        ]);
      });
    };
  }, [path, size, id, targeting, sizeMapping]);
};

export default useAdSlot;
